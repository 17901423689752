import * as React from 'react'

function SvgDots(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 118 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={2.295}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={1.157}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={10.384}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={19.612}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={28.838}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={38.065}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={47.292}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={56.519}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={65.746}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={74.973}
        rx={1.298}
        ry={0.952}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={2.295}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={13.72}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={25.145}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={36.57}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={47.995}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={59.42}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={70.845}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={82.27}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={93.695}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={105.12}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={116.545}
        cy={84.2}
        rx={1.298}
        ry={0.953}
        fill="#101D49"
        fillOpacity={0.38}
      />
    </svg>
  )
}

export default SvgDots
