import React from 'react'
import {
  withStyles,
  createStyles,
  Theme,
  Hidden,
  WithStyles,
} from '@material-ui/core'

import { getFlexBreakpoint } from './styles'
import Image, { BaseProps } from './Image'
import { getMarginX } from '../../utils/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      width: '100%',
      maxWidth: '17rem',
      ...getMarginX('auto'),
      [flexBreakpoint]: {
        display: 'none',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const MobileImage = (props: Props) => {
  return (
    <Hidden mdUp implementation="js">
      <Image {...props} />
    </Hidden>
  )
}

export default withStyles(styles)(MobileImage)
