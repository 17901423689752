import { withStyles, createStyles, Theme } from '@material-ui/core'

import Ellipse from '../../components/SectionWithIcon/Image/Ellipse'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: 'none',
      [up('xl')]: {
        display: 'block',
        bottom: '6.375rem',
        right: '1.3125rem',
        width: '28.5rem',
        zIndex: 1,
      },
    },
  })
}

export default withStyles(styles)(Ellipse)
