import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Hidden,
} from '@material-ui/core'

import Dots from './Dots'
import Avatar from '../../../components/Avatar'
import { heroMobileLgBk } from '../../../components/HeroContent/styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  const mobileLg = up(heroMobileLgBk)

  return createStyles({
    root: {
      position: 'relative',
      transform: 'translateX(-1rem)',
      [mobileLg]: {
        transform: 'none',
      },
    },
    image: {
      width: '8rem',
      zIndex: 2,
      [mobileLg]: {
        width: '10.625rem',
      },
      [up('md')]: {
        width: '15.3125rem',
      },
      [up('lg')]: {
        width: '20rem',
      },
      [up('xl')]: {
        width: '26.9375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Image = ({ classes }: Props) => {
  return (
    <Hidden only="xs">
      <div className={classes.root}>
        <Avatar className={classes.image} objectPosition="left" />
        <Dots />
      </div>
    </Hidden>
  )
}

export default withStyles(styles)(Image)
