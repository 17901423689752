import React from 'react'

import BaseFeatures from '../../../../components/Features'
import Feature from './Feature'
import IconContainer from '../../../../components/Features/Feature/IconContainer'
import Files from './icons/Files'
import PositiveVote from './icons/PositiveVote'
import Rocket from './icons/Rocket'

const Features = () => {
  return (
    <BaseFeatures>
      <Feature
        icon={<IconContainer Icon={Files} />}
        titles={['Increase', 'Sales']}
      />
      <Feature
        icon={<IconContainer Icon={PositiveVote} />}
        titles={['Impress your', 'Customers']}
      />
      <Feature
        icon={<IconContainer Icon={Rocket} />}
        titles={['Load faster', 'Get Sales']}
      />
    </BaseFeatures>
  )
}

export default Features
