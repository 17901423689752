import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import BaseDescription from '../../../components/SectionWithIcon/Body/Description'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3rem',
      [up('md')]: {
        marginBottom: '2.125rem',
      },
      [up('lg')]: {
        marginBottom: '2.5rem',
      },
      [up('xl')]: {
        marginBottom: '3.1875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Description = ({ classes }: Props) => {
  return (
    <BaseDescription classes={classes}>
      Don’t you wish to attract more visitors to your website and convert them
      into paying customers? Your business website is the first place of
      interaction between you and your customers. Having a stunning website can
      leave a lasting impression and help you stand out from your competitors.
    </BaseDescription>
  )
}

export default withStyles(styles)(Description)
