import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import SectionWithIcon from '../../components/SectionWithIcon'
import Body from './Body'
import MobileImage from './MobileImage'
import MobileToolbar from './MobileToolbar'
import DesktopImage from './DesktopImage/index'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '1.9375rem',
      [up('md')]: {
        marginBottom: '4.5rem',
      },
      [up('xl')]: {
        marginBottom: '11.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Importance = ({ classes }: Props) => {
  return (
    <SectionWithIcon
      mobileImage={<MobileImage />}
      mobileToolbar={<MobileToolbar />}
      desktopImage={<DesktopImage />}
      body={<Body />}
      imageAlignment="left"
      classes={classes}
    />
  )
}

export default withStyles(styles)(Importance)
