import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
} from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = ({ palette: { divider }, breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  return createStyles({
    root: {
      padding: '0.625rem',
      [lg]: {
        padding: '1rem',
      },
    },
    icon: {
      color: divider,
      fontSize: '1.25rem',
      [lg]: {
        fontSize: '2.0625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  href: string
  faIcon: IconProp
}

const SocialButton = ({ classes, href, faIcon }: Props) => {
  return (
    <IconButton classes={classes} href={href}>
      <FontAwesomeIcon icon={faIcon} className={classes.icon} />
    </IconButton>
  )
}

export default withStyles(styles)(SocialButton)
