import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import DotsSvg from './DotsSvg'

const styles = () => {
  return createStyles({
    root: {
      position: 'absolute',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Dots = ({ classes }: Props) => {
  return <DotsSvg className={classes.root} />
}

export default withStyles(styles)(Dots)
