import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import PageContainer from '../components/PageContainer'
import GetAQuoteButton from '../components/GetAQuoteButton'

const styles = ({
  palette: {
    primary: { main: primaryColor },
  },
  breakpoints: { up },
}: Theme) => {
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  const paddingYLg: string = '5rem'

  return createStyles({
    root: {
      padding: '1.6875rem 0',
      marginBottom: '6.125rem',
      backgroundColor: primaryColor,
      [md]: {
        paddingTop: '4.4375rem',
        paddingBottom: '2.4375rem',
      },
      [lg]: {
        paddingTop: paddingYLg,
        paddingBottom: paddingYLg,
      },
      [xl]: {
        paddingTop: '6.375rem',
        paddingBottom: '6.0625rem',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    text: {
      fontWeight: 'bold',
      fontSize: '1.0625rem',
      lineHeight: '1.625rem',
      marginBottom: '1.5625rem',
      [md]: {
        fontSize: '1.5625rem',
        lineHeight: '1.8125rem',
        maxWidth: '35.1875rem',
        marginBottom: '2rem',
      },
      [lg]: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
        maxWidth: '48rem',
        marginBottom: '2.75rem',
      },
      [xl]: {
        fontSize: '2.5rem',
        lineHeight: '2.9375rem',
        marginBottom: '3.5625rem',
        maxWidth: '77.25rem',
      },
    },
    emphasized: {
      color: '#F9944C',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Quote = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        <Typography color="secondary" className={classes.text} align="center">
          It’s hard to get a website that has all the elements of actually
          ranking higher, capturing more leads, and growing your business. Well,
          <span className={classes.emphasized}> not anymore!</span>
        </Typography>
        <GetAQuoteButton color="secondary" />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Quote)
