import React from 'react'

import HeroContent from '../../../components/HeroContent'

const Body = () => {
  return (
    <HeroContent
      smallTitle="Empowering Businesses with"
      title={
        <>
          Stunning, Creative &
          <br />
          Responsive <strong>Websites</strong>.
        </>
      }
      description="Discover how you can get a website that ranks higher on search engine results and converts visitors into customers like crazy…"
    />
  )
}

export default Body
