import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Dots from '../Dots'
import Image from '../Image'
import Ellipse from '../Ellipse'
import BaseDesktopImage from '../../../components/SectionWithIcon/DesktopImage'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      position: 'relative',
    },
    image: {
      width: '22.875rem',
      [lg]: {
        width: '28rem',
      },
      [xl]: {
        width: '38.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const DesktopImage = ({ classes }: Props) => {
  return (
    <BaseDesktopImage
      ellipse={<Ellipse />}
      dots={<Dots />}
      classes={classes}
      Image={Image}
    />
  )
}

export default withStyles(styles)(DesktopImage)
