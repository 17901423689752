import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Body from './Body'
import Image from './Image'
import PageContainer from '../../components/PageContainer'

const styles = (theme: Theme) => {
  const {
    breakpoints: { up },
  } = theme
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginTop: '1.4375rem',
      marginBottom: '4rem',
      overflow: 'hidden',
      [md]: {
        marginTop: '1.1875rem',
        marginBottom: '4.875rem',
      },
      [lg]: {
        marginBottom: '9rem',
      },
      [xl]: {
        marginTop: '3.625rem',
        marginBottom: '15rem',
      },
    },
    content: {
      overflowX: 'visible',
      display: 'flex',
      alignItems: 'flex-end',
      [lg]: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Hero = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <div className={classes.content}>
          <Body />
          <Image />
        </div>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Hero)
