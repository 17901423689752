import React from 'react'

import { links } from '../../../contants/links'
import LinkWithArrow from '../../../components/LinkWithArrow'

const ViewAllButton = () => {
  const href: string = links.portfolio
  return <LinkWithArrow href={href}>View All Projects</LinkWithArrow>
}

export default ViewAllButton
