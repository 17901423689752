import React from 'react'
import BaseTitle from '../../../components/SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    Now here is the
    <br />
    twist
  </BaseTitle>
)

export default Title
