import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Image from './Image'
import BaseMobileImage from '../../components/SectionWithIcon/MobileImage'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      marginBottom: '3.25rem',
      width: '100%',
      maxWidth: '25rem',
      [flexBreakpoint]: {
        display: 'none',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const MobileImage = ({ classes }: Props) => {
  return <BaseMobileImage classes={classes} Image={Image} />
}

export default withStyles(styles)(MobileImage)
