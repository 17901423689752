import { withStyles, createStyles, Theme } from '@material-ui/core'

import Dots from '../../components/Dots'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  return createStyles({
    root: {
      bottom: '9.6875rem',
      left: '-0.5625rem',
      width: '6.5rem',
      [flexBreakpoint]: {
        display: 'none',
      },
      [up('lg')]: {
        left: '-2rem',
        bottom: '17em',
        display: 'block',
        width: '10rem',
      },
      [up('xl')]: {
        bottom: '18.4375rem',
        width: '12.375rem',
        left: '-3.625rem',
      },
    },
  })
}

export default withStyles(styles)(Dots)
