import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import BaseDescription from '../../../components/SectionWithIcon/Body/Description'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '2.4375rem',
      [up('md')]: {
        marginBottom: '1.875rem',
      },
      [up('lg')]: {
        marginBottom: '2.6875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Description = ({ classes }: Props) => {
  return (
    <BaseDescription classes={classes}>
      With years of web development experience, Eduardo creates a beautiful and
      responsive website for your business that will help you generate more
      leads on auto-pilot and grow your business faster. At SuperCoder, you get
      the attention to detail that your business deserves and you get a website
      that best aligns with your branding and business goals.
    </BaseDescription>
  )
}

export default withStyles(styles)(Description)
