import { withStyles, createStyles, Theme } from '@material-ui/core'

import Dots from '../../components/Dots'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  return createStyles({
    root: {
      width: '7rem',
      bottom: '13.5rem',
      right: '0.4375rem',
      [flexBreakpoint]: {
        bottom: '13.5rem',
        right: 0,
        width: '7rem',
      },
      [up('lg')]: {
        bottom: '16rem',
        width: '10rem',
      },
      [up('xl')]: {
        bottom: '25rem',
        width: '12.375rem',
      },
    },
  })
}

export default withStyles(styles)(Dots)
