import React from 'react'

import BaseToolbar from '../../../components/SectionWithIcon/Toolbar'
import { ToolbarStyleProps } from '../../../components/SectionWithIcon/types'
import Title from './Title'

const Toolbar = (props: ToolbarStyleProps) => {
  return <BaseToolbar title={<Title />} {...props} />
}

export default Toolbar
