import { withStyles, createStyles, Theme } from '@material-ui/core'
import SectionToolbar from '../SectionToolbar'

export const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3.25rem',
      [up('md')]: {
        marginBottom: '1.875rem',
      },
      [up('lg')]: {
        marginBottom: '2.5rem',
      },
      [up('xl')]: {
        marginBottom: '3.3125rem',
      },
    },
  })
}

export default withStyles(styles)(SectionToolbar)
