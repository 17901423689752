import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import EllipseSvg from './EllipseSvg'

const styles = () => {
  return createStyles({
    root: {
      position: 'absolute',
      zIndex: 1,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Ellipse = ({ classes }: Props) => {
  return <EllipseSvg className={classes.root} />
}

export default withStyles(styles)(Ellipse)
