import * as React from 'react'

function SvgBlueBarChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 38 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.156 27.438h-1.713V2.558c0-1.158-.942-2.1-2.1-2.1h-3.83c-1.159 0-2.1.942-2.1 2.1v24.88H23.06V13.83c0-1.158-.942-2.1-2.1-2.1h-3.83c-1.158 0-2.1.942-2.1 2.1v13.608h-4.35V20.39c0-1.158-.943-2.1-2.101-2.1H4.75c-1.158 0-2.1.942-2.1 2.1v7.048H.844a.781.781 0 100 1.562h36.312a.781.781 0 000-1.562zm-28.038 0H4.212V20.39c0-.296.241-.537.538-.537h3.83c.296 0 .538.24.538.537v7.048zm12.38 0h-4.904V13.83c0-.296.24-.537.537-.537h3.83c.297 0 .538.24.538.537v13.608zm12.382 0h-4.905V2.558c0-.297.241-.538.537-.538h3.83c.297 0 .538.241.538.538v24.88z"
        fill="#0F69C9"
      />
    </svg>
  )
}

export default SvgBlueBarChart
