import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import BaseDescription from '../../../components/SectionWithIcon/Body/Description'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '2.8125rem',
      [md]: {
        marginBottom: '1.875rem',
      },
      [lg]: {
        marginBottom: '2.5rem',
      },
      [xl]: {
        marginBottom: '2.9375rem',
      },
    },
    main: {
      marginBottom: '1.625rem',
      [md]: {
        marginBottom: '2.0625rem',
      },
      [lg]: {
        marginBottom: '2.25rem',
      },
      [xl]: {
        marginBottom: '2.8125rem',
        maxWidth: '50.875rem',
      },
    },
    emphasized: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      [lg]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
      [xl]: {
        fontSize: '1.5625rem',
        lineHeight: '2.1875rem',
        maxWidth: '40.8125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Description = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <BaseDescription classes={{ root: classes.main }}>
        You might have a responsive and beautiful website but not ranking in the
        search results or getting any visitors. Guess what? If your website is
        slow, customers will leave. So, by not having a blazing-fast website,
        you’re missing out on hundreds if not thousands of customers every
        single day. To leave a good first-impression, you need a website that
        loads faster, looks beautiful, is mobile responsive, and retains
        visitors for longer.
      </BaseDescription>
      <Typography color="textPrimary" className={classes.emphasized}>
        What’s the benefit of having a stunning and beautiful website if no one
        wants to use it?
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Description)
