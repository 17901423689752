import { withStyles, createStyles, Theme } from '@material-ui/core'

import Ellipse from '../../components/SectionWithIcon/Image/Ellipse'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  return createStyles({
    root: {
      bottom: '11.875rem',
      right: '1.875rem',
      [flexBreakpoint]: {
        bottom: '11.875rem',
        right: 0,
        width: '16.8125rem',
      },
      [up('lg')]: {
        bottom: '15rem',
        left: 'auto',
        width: '23rem',
      },
      [up('xl')]: {
        bottom: '23rem',
        width: '28.5rem',
      },
    },
  })
}

export default withStyles(styles)(Ellipse)
