import { withStyles, createStyles, Theme } from '@material-ui/core'

import { getFlexBreakpoint } from '../styles'
import { Toolbar } from '../types'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      display: 'none',
      [flexBreakpoint]: {
        display: 'block',
      },
    },
  })
}

export const getDesktopToolbar = (Toolbar: Toolbar) =>
  withStyles(styles)(Toolbar)
