import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageProps } from '../../components/SectionWithIcon/types'

interface Props extends ImageProps {}

const Image = ({ className }: Props) => {
  return (
    <StaticImage
      className={className}
      src="./section-image.png"
      alt="A laptop displaying an online learning website. It has a section with a purple background and the title 'Learn new skills online with top educators.' It shows an image of a female student learning on her laptop, and shows a search bar to find courses."
    />
  )
}

export default Image
