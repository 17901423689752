import React from 'react'
import { Typography } from '@material-ui/core'

import BaseTitle from '../../../components/SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    Introducing Supercoder
    <br />
    with{' '}
    <Typography color="primary" component="span" variant="h2">
      Eduardo
    </Typography>
  </BaseTitle>
)

export default Title
