import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import BaseMobileImage from '../../components/SectionWithIcon/MobileImage'
import Avatar from '../../components/Avatar'
import Dots from './Dots'
import Ellipse from './Ellipse'

const styles = () => {
  return createStyles({
    root: {
      marginBottom: '1.9375rem',
      position: 'relative',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const MobileImage = ({ classes }: Props) => {
  return (
    <BaseMobileImage
      classes={classes}
      Image={Avatar}
      dots={<Dots />}
      ellipse={<Ellipse />}
    />
  )
}

export default withStyles(styles)(MobileImage)
