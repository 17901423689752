import React from 'react'
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
  Hidden,
} from '@material-ui/core'

import { getFlexBreakpoint } from '../styles'
import Image, { BaseProps } from '../Image'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      display: 'none',
      [flexBreakpoint]: {
        display: 'block',
      },
    },
    image: {},
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const DesktopImage = (props: Props) => {
  return (
    <Hidden smDown implementation="js">
      <Image {...props} />
    </Hidden>
  )
}

export default withStyles(styles)(DesktopImage)
