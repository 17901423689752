import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Hidden,
} from '@material-ui/core'

import Dots from '../Dots'
import Image from '../Image'
import Ellipse from '../Ellipse'
import BaseDesktopImage from '../../../components/SectionWithIcon/DesktopImage'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
    },
    image: {
      width: '58.125rem',
      [theme.breakpoints.up('lg')]: {
        width: '60rem',
      },
      [theme.breakpoints.up('xl')]: {
        width: '72.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const DesktopImage = ({ classes }: Props) => {
  return (
    <Hidden smDown implementation="js">
      <BaseDesktopImage
        ellipse={<Ellipse />}
        dots={<Dots />}
        classes={classes}
        Image={Image}
      />
    </Hidden>
  )
}

export default withStyles(styles)(DesktopImage)
