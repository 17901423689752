import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core'
import { Link } from 'gatsby'
import ArrowIcon from '@material-ui/icons/ArrowForward'

const styles = (theme: Theme) => {
  const size: string = '1.3125rem'
  const xl = theme.breakpoints.up('xl')

  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    link: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    button: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      textDecoration: 'underline',
      color: theme.palette.text.primary,
      [xl]: {
        fontSize: '1.5625rem',
        lineHeight: '1.8125rem',
      },
    },
    endIcon: {
      width: size,
      height: size,
      marginLeft: '0.5rem',
      color: '#F9944C',
      [xl]: {
        marginLeft: '1.125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  href: string
  children: React.ReactNode
}

const LinkWithArrow = ({ classes, href, children }: Props) => {
  return (
    <div className={classes.root}>
      <Link to={href} className={classes.link}>
        <Button
          href={href}
          variant="text"
          classes={{ root: classes.button, endIcon: classes.endIcon }}
          endIcon={<ArrowIcon />}
        >
          {children}
        </Button>
      </Link>
    </div>
  )
}

export default withStyles(styles)(LinkWithArrow)
