import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Description from './Description'
import Features from './Features'
import DesktopToolbar from './DesktopToolbar'
import { getFlexBreakpoint } from '../../../components/SectionWithIcon/styles'
import BaseBody from '../../../components/SectionWithIcon/Body'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const { up } = theme.breakpoints
  return createStyles({
    root: {
      [flexBreakpoint]: {
        paddingTop: '3.3125rem',
        width: '25.1875rem',
      },
      [up('lg')]: {
        width: '30rem',
        paddingTop: '8rem',
        order: 1,
      },
      [up('xl')]: {
        width: '49.4375rem',
        paddingTop: '13.75rem',
        marginRight: '8.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Body = ({ classes }: Props) => {
  return (
    <BaseBody
      classes={classes}
      desktopToolbar={<DesktopToolbar />}
      description={<Description />}
      features={<Features />}
    />
  )
}

export default withStyles(styles)(Body)
