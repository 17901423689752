import { withStyles, createStyles, Theme } from '@material-ui/core'

import { getFlexBreakpoint } from './styles'
import { Toolbar } from './types'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      [flexBreakpoint]: {
        display: 'none',
      },
    },
  })
}

export const getMobileToolbar = (Toolbar: Toolbar) =>
  withStyles(styles)(Toolbar)
