import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import SectionWithIcon from '../../components/SectionWithIcon'
import Body from './Body'
import MobileImage from './MobileImage'
import MobileToolbar from './MobileToolbar'
import DesktopImage from './DesktopImage/index'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '3.625rem',
      [up('md')]: {
        marginBottom: '4.5rem',
      },
      [xl]: {
        marginBottom: '11.25rem',
      },
    },
    container: {
      [xl]: {
        maxWidth: '96.4375rem',
        paddingLeft: '11.25rem',
        paddingRight: '11rem',
      },
    },
    content: {
      [flexBreakpoint]: {
        minHeight: '36.3125rem',
      },
      [xl]: {
        minHeight: '61.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Solution = ({ classes }: Props) => {
  return (
    <SectionWithIcon
      classes={classes}
      mobileImage={<MobileImage />}
      mobileToolbar={<MobileToolbar />}
      desktopImage={<DesktopImage />}
      body={<Body />}
      imageAlignment="left"
    />
  )
}

export default withStyles(styles)(Solution)
