import React from 'react'
import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { handleImgNotFoundError } from '../utils/errors'

interface Props extends Omit<GatsbyImageProps, 'className' | 'image' | 'alt'> {
  className: GatsbyImageProps['className']
}

const Avatar = ({ className, ...other }: Props) => {
  const data = useStaticQuery(graphql`
    query Avatar {
      file(absolutePath: { regex: "/Home/hero/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const image = getImage(data.file)
  if (!image) return handleImgNotFoundError()
  return (
    <GatsbyImage
      image={image}
      alt="Person crossing their arms"
      className={className}
      {...other}
    />
  )
}

export default Avatar
