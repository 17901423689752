import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Description from './Description'
import Features from './Features'
import DesktopToolbar from './DesktopToolbar'
import { getFlexBreakpoint } from '../../../components/SectionWithIcon/styles'
import BaseBody from '../../../components/SectionWithIcon/Body'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const { up } = theme.breakpoints
  return createStyles({
    root: {
      [flexBreakpoint]: {
        paddingTop: '1.75rem',
        maxWidth: '24.375rem',
      },
      [up('lg')]: {
        maxWidth: '30rem',
      },
      [up('xl')]: {
        maxWidth: '49.4375rem',
        paddingTop: 0,
        marginLeft: '5.9375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Body = ({ classes }: Props) => {
  return (
    <BaseBody
      classes={classes}
      desktopToolbar={<DesktopToolbar />}
      description={<Description />}
      features={<Features />}
    />
  )
}

export default withStyles(styles)(Body)
