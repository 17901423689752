import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import { Image } from '../types'

const styles = () => {
  return createStyles({
    root: {},
    image: {
      zIndex: 2,
    },
  })
}

export interface BaseProps {
  Image: Image
  dots?: React.ReactNode
  ellipse?: React.ReactNode
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const SectionImage = ({ classes, Image, dots, ellipse }: Props) => {
  return (
    <div className={classes.root}>
      <Image className={classes.image} />
      {dots ? dots : null}
      {ellipse ? ellipse : null}
    </div>
  )
}

export default withStyles(styles)(SectionImage)
