import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import PageContainer from '../components/PageContainer'
import GetAQuoteButton from '../components/GetAQuoteButton'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')

  const paddingYLg: string = '3rem'

  return createStyles({
    root: {
      background: 'rgba(15, 52, 143, 0.05)',
      padding: '2.125rem 0',
      marginBottom: '2.5625rem',
      [md]: {
        paddingTop: '2.8125rem',
        paddingBottom: '2.125rem',
        marginBottom: '7.3125rem',
      },
      [lg]: {
        paddingTop: paddingYLg,
        paddingBottom: paddingYLg,
      },
      [xl]: {
        paddingTop: '4.0625rem',
        paddingBottom: '2.75rem',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [md]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    title: {
      fontSize: '1rem',
      lineHeight: '1.5625rem',
      fontWeight: 'bold',
      marginBottom: '1.25rem',
      textAlign: 'center',
      [md]: {
        textAlign: 'left',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginBottom: 0,
      },
      [lg]: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
      },
      [xl]: {
        fontSize: '2.5rem',
        lineHeight: '2.9375rem',
      },
    },
    button: {
      [xl]: {
        padding: '0.9375rem 2.625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const CallToAction = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <div className={classes.content}>
          <Typography
            className={classes.title}
            color="textPrimary"
            component="h2"
          >
            Looking for a website?
            <br />
            Let’s connect now and discuss your big Idea!
          </Typography>
          <GetAQuoteButton classes={{ root: classes.button }} />
        </div>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(CallToAction)
