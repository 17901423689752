import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import BaseDesktopImage from '../../components/SectionWithIcon/DesktopImage'
import Avatar from '../../components/Avatar'
import Dots from './Dots'
import Ellipse from './Ellipse'

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      [up('md')]: {
        order: 2,
        position: 'relative',
      },
    },
    image: {
      width: '22rem',
      [lg]: {
        width: '30rem',
      },
      [xl]: {
        width: '38.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const DesktopImage = ({ classes }: Props) => {
  return (
    <BaseDesktopImage
      classes={classes}
      Image={Avatar}
      dots={<Dots />}
      ellipse={<Ellipse />}
    />
  )
}

export default withStyles(styles)(DesktopImage)
