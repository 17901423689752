import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ImageProps } from '../../../components/SectionWithIcon/types'
import { handleImgNotFoundError } from '../../../utils/errors'

const Image = (props: ImageProps) => {
  const data = useStaticQuery(graphql`
    query GetProblemImage {
      file(absolutePath: { regex: "/Home/solution/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const image = getImage(data.file)

  if (!image) return handleImgNotFoundError()
  return <GatsbyImage image={image} alt="Woman crossing her arms" {...props} />
}

export default Image
