import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

const styles = () => {
  return createStyles({
    root: {
      flexShrink: 0,
      flexGrow: 1,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  desktopToolbar: React.ReactNode
  description: React.ReactNode
  features: React.ReactNode
}

const Body = ({ classes, desktopToolbar, description, features }: Props) => {
  return (
    <div className={classes.root}>
      {desktopToolbar}
      {description}
      {features}
    </div>
  )
}

export default withStyles(styles)(Body)
