import React from 'react'
import BaseTitle from '../../../components/SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    Your Business Website
    <br />
    is Important
  </BaseTitle>
)

export default Title
