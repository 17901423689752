import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import Image from './Image'
import BaseMobileImage from '../../components/SectionWithIcon/MobileImage'
import Dots from './Dots'
import Ellipse from './Ellipse'

const styles = () => {
  return createStyles({
    root: {
      marginBottom: '2.4375rem',
      position: 'relative',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const MobileImage = ({ classes }: Props) => {
  return (
    <BaseMobileImage
      classes={classes}
      Image={Image}
      dots={<Dots />}
      ellipse={<Ellipse />}
    />
  )
}

export default withStyles(styles)(MobileImage)
