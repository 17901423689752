import React from 'react'

import BaseFeatures from '../../../../components/Features'
import Feature from '../../../../components/Features/Feature'
import IconContainer from '../../../../components/Features/Feature/IconContainer'
import Rocket from './icons/Rocket'
import Comparison from './icons/Comparison'
import BarChart from './icons/BarChart'
import BlueBarChart from './icons/BlueBarChart'

const Features = () => {
  return (
    <BaseFeatures>
      <Feature
        icon={<IconContainer Icon={Rocket} />}
        titles={['Blazing-Fast', 'Loading Speeds']}
      />
      <Feature
        icon={<IconContainer Icon={Comparison} />}
        titles={['Mobile', 'Responsive']}
      />
      <Feature
        icon={<IconContainer Icon={BarChart} />}
        titles={['Easy to', 'Navigate']}
      />
      <Feature
        icon={<IconContainer Icon={BlueBarChart} />}
        titles={['Fully Optimized', 'Website']}
      />
    </BaseFeatures>
  )
}

export default Features
