import * as React from 'react'

function SvgEllipseSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 456 456"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={228} cy={228} r={228} fill="#5665AA" fillOpacity={0.1} />
    </svg>
  )
}

export default SvgEllipseSvg
