import * as React from 'react'

function SvgRocket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#rocket_svg__clip0)" fill="#673E66">
        <path d="M12.709 15.594a.665.665 0 00-.55-.362l-5.608-.374a.654.654 0 00-.665.422L1.843 25.613a.666.666 0 001.02.776l3.357-2.517 1.953.39a.666.666 0 00.754-.415c1.049-2.749 2.312-5.304 3.753-7.596a.667.667 0 00.029-.657zm-4.824 7.251l-1.697-.339a.672.672 0 00-.53.12l-1.72 1.29 3.011-7.695 4.026.267a45.023 45.023 0 00-3.09 6.357zM25.142 33.45l-.374-5.61a.667.667 0 00-1.02-.52c-2.292 1.44-4.847 2.703-7.595 3.753a.665.665 0 00-.416.753l.39 1.954-2.516 3.355a.667.667 0 00.777 1.023l10.332-4.044a.667.667 0 00.422-.665zm-9.059 2.61l1.29-1.719a.667.667 0 00.12-.53l-.34-1.697a44.987 44.987 0 006.357-3.089l.269 4.024-7.696 3.011zM19.332 20H14.84a.668.668 0 00-.582.343L8.62 30.473a.667.667 0 00.927.894l10.13-6.13c.2-.12.321-.337.321-.57v-4a.667.667 0 00-.666-.667zm-.667 4.291l-7.658 4.635 4.225-7.592h3.433v2.957z" />
        <path d="M39.966.643a.667.667 0 00-.609-.61c-.221-.017-22.174-1.55-31.675 23.338a.666.666 0 00.15.71l3.17 3.169a.67.67 0 001.056-.15l3.176-5.766h3.431v2.965l-5.8 3.663a.669.669 0 00-.116 1.035l3.17 3.17a.666.666 0 00.71.151C41.516 22.816 39.984.863 39.965.643zM36.47 12.47c-2.339 5.787-7.692 13.655-19.914 18.448l-2.273-2.273 5.406-3.414a.668.668 0 00.31-.564v-4a.667.667 0 00-.666-.666h-4.493a.667.667 0 00-.584.345l-2.938 5.335-2.235-2.236C17.423 2.165 35.203 1.295 38.66 1.343c.024 1.555-.118 5.998-2.19 11.127zM11.42 29.443a.665.665 0 00-.602-.261.667.667 0 00-.261-.602c-1.006-.76-2.303-1.012-3.56-.694A4.484 4.484 0 004 30.386L.06 39.056a.665.665 0 00.883.884l8.67-3.942a4.482 4.482 0 002.5-2.995c.319-1.256.066-2.554-.694-3.56zm-.6 3.232c-.238.941-.88 1.71-1.758 2.11L2.009 37.99l3.206-7.053a3.156 3.156 0 012.85-1.852c.416 0 .822.089 1.192.261l-.635 1.124a.666.666 0 00.909.908l1.123-.635c.273.588.335 1.267.166 1.932z" />
        <path d="M39.966.643a.668.668 0 00-.609-.61c-.26-.02-6.44-.488-13.707 2.86a.666.666 0 00-.193 1.077L36.03 14.542a.667.667 0 001.077-.192C40.456 7.083 39.987.902 39.967.643zm-3.68 12.268l-9.197-9.197c5.32-2.246 9.979-2.4 11.571-2.374.027 1.594-.123 6.239-2.375 11.57zM29.44 10.56a5.755 5.755 0 00-8.13 0 5.755 5.755 0 000 8.13 5.73 5.73 0 004.065 1.681c1.472 0 2.944-.56 4.065-1.681a5.755 5.755 0 000-8.13zm-.942 7.187a4.421 4.421 0 01-6.246 0 4.421 4.421 0 010-6.245 4.403 4.403 0 013.123-1.292c1.13 0 2.262.431 3.123 1.292a4.421 4.421 0 010 6.245z" />
        <path d="M26.7 15.95a1.876 1.876 0 01-2.651-2.651c.73-.73 1.92-.731 2.651 0a.666.666 0 10.943-.943 3.212 3.212 0 00-4.537 0 3.211 3.211 0 000 4.536 3.199 3.199 0 002.269.939c.822 0 1.643-.313 2.268-.939a.666.666 0 10-.943-.942z" />
      </g>
      <defs>
        <clipPath id="rocket_svg__clip0">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgRocket
