import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import SectionWithIcon from '../../components/SectionWithIcon'
import Body from './Body'
import MobileImage from './MobileImage'
import MobileToolbar from './MobileToolbar'
import DesktopImage from './DesktopImage'

const styles = (theme: Theme) => {
  const {
    breakpoints: { up },
  } = theme
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '4.4375rem',
      [md]: {
        marginBottom: '6.5625rem',
      },
      [lg]: {
        marginBottom: '8rem',
      },
      [xl]: {
        marginBottom: '9rem',
      },
    },
    content: {
      [lg]: {
        justifyContent: 'space-between',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const IntroducingSupercoder = ({ classes }: Props) => {
  return (
    <SectionWithIcon
      classes={classes}
      mobileImage={<MobileImage />}
      mobileToolbar={<MobileToolbar />}
      desktopImage={<DesktopImage />}
      body={<Body />}
      imageAlignment="right"
    />
  )
}

export default withStyles(styles)(IntroducingSupercoder)
