import { withStyles, createStyles, Theme } from '@material-ui/core'

import Ellipse from '../../components/SectionWithIcon/Image/Ellipse'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  return createStyles({
    root: {
      bottom: '7.1875rem',
      left: '0.5625rem',
      width: '9.125rem',
      [flexBreakpoint]: {
        bottom: '8.375rem',
        right: '-1.375rem',
        width: '16.125rem',
        left: 'auto',
      },
      [up('lg')]: {
        bottom: '14rem',
        left: '-1.25rem',
      },
      [up('xl')]: {
        bottom: '13.625rem',
        width: '28.5rem',
        left: '-1.5rem',
      },
    },
  })
}

export default withStyles(styles)(Ellipse)
