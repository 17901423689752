import React from 'react'

import Layout from '../components/Layout'
import Hero from './Hero'
import Projects from './Projects'
import Importance from './Importance'
import CallToAction from './CallToAction'
import Solution from './Solution'
import Quote from './Quote'
import IntroducingSupercoder from './IntroducingSupercoder'
import Testimonials from '../components/Testimonials'
import Social from '../components/Social'
import Contact from '../components/Contact'
import Seo from '../components/Seo'
import SocialToolbar from '../components/SocialToolbar'

export const Home = () => {
  return (
    <Layout header={<Hero />}>
      <Seo title="Home" />
      <SocialToolbar />
      <Projects />
      <Importance />
      <CallToAction />
      <Solution />
      <Quote />
      <IntroducingSupercoder />
      <Testimonials />
      <Social />
      <Contact />
    </Layout>
  )
}

export default Home
