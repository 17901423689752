import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

const styles = ({
  palette: {
    text: { secondary: textSecondary },
  },
}: Theme) => {
  return createStyles({
    root: {
      color: textSecondary,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Description = ({ classes, children }: Props) => {
  return (
    <Typography classes={classes} variant="body1">
      {children}
    </Typography>
  )
}

export default withStyles(styles)(Description)
