import * as React from 'react'

function SvgBarChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#barChart_svg__clip0)" fill="#673E66">
        <path d="M38.156 38.437h-1.713v-24.88c0-1.158-.942-2.1-2.1-2.1h-3.83c-1.159 0-2.1.942-2.1 2.1v24.88H24.06V24.83c0-1.158-.942-2.1-2.1-2.1h-3.83c-1.158 0-2.1.942-2.1 2.1v13.607h-4.35V31.39c0-1.158-.943-2.1-2.101-2.1H5.75c-1.158 0-2.1.942-2.1 2.1v7.047H1.844a.781.781 0 000 1.563h36.312a.781.781 0 100-1.563zm-28.038 0H5.212V31.39c0-.296.241-.537.538-.537h3.83c.296 0 .537.24.537.537v7.047zm12.38 0h-4.904V24.83c0-.296.24-.538.537-.538h3.83c.297 0 .538.241.538.538v13.607zm12.382 0h-4.905v-24.88c0-.296.241-.537.537-.537h3.83c.297 0 .538.24.538.537v24.88zM36.44.822a.778.778 0 00-.819-.82L35.583 0h-4.726a.781.781 0 100 1.563h2.918l-7.723 7.723-2.912-2.913a.781.781 0 00-1.105 0L7.99 20.418a.781.781 0 001.105 1.105L22.587 8.03l2.913 2.913c.305.305.8.305 1.105 0l8.275-8.276V5.31a.781.781 0 001.563 0V.859L36.44.822zM5.91 24.477a.781.781 0 100-1.562.781.781 0 000 1.562z" />
      </g>
      <defs>
        <clipPath id="barChart_svg__clip0">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgBarChart
