import React from 'react'

import BaseFeatures from '../../../../components/Features'
import Feature from '../../../../components/Features/Feature'
import IconContainer from '../../../../components/Features/Feature/IconContainer'
import Rocket from './icons/Rocket'
import Comparison from './icons/Comparison'
import BarChart from './icons/BarChart'
import BlueBarChart from './icons/BlueBarChart'

const Features = () => {
  return (
    <BaseFeatures>
      <Feature
        icon={<IconContainer Icon={Rocket} />}
        titles={['Blazing-Fast', 'Website']}
      />
      <Feature
        icon={<IconContainer Icon={Comparison} />}
        titles={['Pixel-Perfect', 'Design']}
      />
      <Feature
        icon={<IconContainer Icon={BarChart} />}
        titles={['Increase', 'Sales']}
      />
      <Feature
        icon={<IconContainer Icon={BlueBarChart} />}
        titles={['Created in best', 'Technologies']}
      />
    </BaseFeatures>
  )
}

export default Features
