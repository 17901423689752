import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SectionToolbar from '../../components/SectionToolbar'
import List from '../../components/Projects'
import ViewAllButton from './ViewAllButton'
import Title from './Title'
import { ProjectsQuery } from '../../types/Project'
import ProjectsSection from '../../components/ProjectsSection'

const Projects = () => {
  const data: ProjectsQuery = useStaticQuery(graphql`
    query FeaturedProject {
      allStrapiProjects(
        sort: { order: ASC, fields: order }
        filter: { featured: { eq: true } }
      ) {
        nodes {
          slug
          title
          description
          url
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ProjectsSection>
      <SectionToolbar title={<Title />} />
      <List projects={data.allStrapiProjects.nodes} />
      <ViewAllButton />
    </ProjectsSection>
  )
}

export default Projects
