import * as React from 'react'

function SvgComparison(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.888 13.36c.001-.073.003-.146.003-.22C32.89 6.034 27.108.25 20 .25S7.11 6.033 7.11 13.14c0 .074 0 .147.002.22C2.896 15.49 0 19.845 0 24.86c0 7.107 5.818 12.89 12.969 12.89 2.592 0 5.008-.77 7.031-2.092a12.814 12.814 0 007.031 2.092C34.182 37.75 40 31.967 40 24.86c0-5.015-2.896-9.37-7.112-11.5zM20 32.713a10.527 10.527 0 01-3.492-7.163c1.11.313 2.282.481 3.492.481s2.381-.168 3.492-.48A10.527 10.527 0 0120 32.712zm0-9.026c-1.18 0-2.314-.194-3.374-.553.4-2.418 1.624-4.56 3.374-6.128a10.541 10.541 0 013.374 6.128c-1.06.359-2.195.553-3.374.553zM14.44 22.1a10.57 10.57 0 01-4.846-7.24 10.652 10.652 0 013.375-.546c1.841 0 3.574.474 5.083 1.308a12.886 12.886 0 00-3.613 6.478zm7.508-6.478a10.481 10.481 0 015.083-1.309c1.18 0 2.315.193 3.375.546a10.57 10.57 0 01-4.845 7.24 12.886 12.886 0 00-3.613-6.477zM20 2.594c5.581 0 10.163 4.358 10.523 9.85a13.009 13.009 0 00-3.492-.475c-2.592 0-5.008.77-7.031 2.092a12.815 12.815 0 00-7.031-2.092c-1.21 0-2.38.166-3.492.475.36-5.492 4.941-9.85 10.523-9.85zm-7.031 32.812c-5.859 0-10.625-4.731-10.625-10.547 0-3.79 2.026-7.12 5.06-8.98a12.938 12.938 0 006.74 8.743l-.003.237c0 3.62 1.5 6.895 3.91 9.239a10.48 10.48 0 01-5.082 1.308zm14.062 0a10.48 10.48 0 01-5.082-1.308 12.852 12.852 0 003.91-9.239l-.003-.237a12.938 12.938 0 006.74-8.743c3.034 1.86 5.06 5.19 5.06 8.98 0 5.816-4.766 10.547-10.625 10.547z"
        fill="#F05F6B"
      />
    </svg>
  )
}

export default SvgComparison
