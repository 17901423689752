import { withStyles, createStyles, Theme } from '@material-ui/core'

import Feature from '../../../../components/Features/Feature'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up('xl')]: {
        flexBasis: '33%',
      },
    },
  })
}

export default withStyles(styles)(Feature)
