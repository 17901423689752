import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../PageContainer'
import { getFlexBreakpoint } from './styles'

type Direction = 'left' | 'right'

interface BaseProps {
  mobileToolbar: React.ReactNode
  mobileImage: React.ReactNode
  desktopImage: React.ReactNode
  body: React.ReactNode
  imageAlignment: Direction
}

const getJustifyContent = (props: BaseProps) => {
  return props.imageAlignment === 'left' ? 'flex-end' : 'flex-start'
}

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'visible',
    },
    container: {
      overflowY: 'visible',
    },
    content: {
      overflow: 'visible',
      [flexBreakpoint]: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: getJustifyContent,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const SectionWithIcon = ({
  classes,
  mobileToolbar,
  mobileImage,
  desktopImage,
  body,
}: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <div className={classes.content}>
          {mobileToolbar}
          {mobileImage}
          {desktopImage}
          {body}
        </div>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(SectionWithIcon)
