import * as React from 'react'

function SvgFiles(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.18 18.99a.586.586 0 100-1.172h-3.84a.586.586 0 100 1.172h3.84zM12.9 29.164a.586.586 0 00-.586.586v.938a.586.586 0 001.172 0v-.938a.586.586 0 00-.586-.586zm10.238-3.019a.586.586 0 000-1.172h-8.184a.586.586 0 100 1.172h8.184zm-8.77-3.722c0 .324.262.586.586.586h8.184a.586.586 0 100-1.172h-8.184a.586.586 0 00-.586.586zM38.514 1.82a.586.586 0 10-.538 1.041c.526.271.852.807.852 1.398V23.4c0 .867-.705 1.572-1.572 1.572H29.33V15.49c0-.38-.075-.742-.21-1.073h5.17a.586.586 0 000-1.172h-6.053a2.84 2.84 0 00-1.763-.611h-4.907V4.259c0-.867.705-1.572 1.572-1.572h12.188a.586.586 0 000-1.172H23.138a2.747 2.747 0 00-2.744 2.744v8.374H18.8c.775-4.385-2.614-8.379-7.025-8.379-3.934 0-7.134 3.2-7.134 7.134 0 .87.156 1.703.442 2.473L.875 18.07a2.981 2.981 0 000 4.219 2.981 2.981 0 004.218 0l3.67-3.67v17.011a2.86 2.86 0 002.856 2.856h.7a.586.586 0 100-1.171h-.7a1.686 1.686 0 01-1.684-1.685V18.282c3.622.967 7.31-1.038 8.552-4.477h7.986c.93 0 1.685.755 1.685 1.684v20.14c0 .93-.756 1.685-1.685 1.685H15.255a.586.586 0 100 1.171h11.218a2.86 2.86 0 002.856-2.856v-9.484h7.927A2.747 2.747 0 0040 23.401V4.26c0-1.031-.57-1.966-1.486-2.44zM4.264 21.46a1.81 1.81 0 11-2.561-2.562l3.912-3.912a7.188 7.188 0 002.562 2.561l-3.913 3.912zm7.51-4.11a5.97 5.97 0 01-5.962-5.962 5.969 5.969 0 015.962-5.962 5.969 5.969 0 015.962 5.962 5.97 5.97 0 01-5.962 5.963zm14.331-7.242a.586.586 0 100 1.172h8.184a.586.586 0 100-1.172h-8.184zm-.326 20.58v-.938a.586.586 0 00-1.172 0v.938a.586.586 0 101.172 0zM11.774 6.94a4.454 4.454 0 00-4.449 4.448 4.454 4.454 0 004.449 4.45 4.454 4.454 0 004.449-4.45 4.454 4.454 0 00-4.449-4.448zm0 7.725a3.28 3.28 0 01-3.277-3.277 3.28 3.28 0 013.277-3.277 3.28 3.28 0 013.277 3.277 3.28 3.28 0 01-3.277 3.277zm5.403 16.867c0 1.114.839 2.021 1.87 2.021 1.03 0 1.868-.907 1.868-2.021 0-1.115-.838-2.022-1.869-2.022-1.03 0-1.869.907-1.869 2.021zm2.566 0c0 .468-.313.85-.697.85-.384 0-.697-.382-.697-.85 0-.47.313-.85.697-.85.384 0 .697.38.697.85z"
        fill="#3C9A9D"
      />
    </svg>
  )
}

export default SvgFiles
