import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import SocialButton from './SocialButton'
import social from '../../config/social'

const styles = ({ breakpoints: { up } }: Theme) => {
  const md = up('md')
  const lg = up('lg')
  const xl = up('xl')
  return createStyles({
    root: {
      display: 'none',
      [md]: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        bottom: 0,
        left: '1.25rem',
        alignItems: 'center',
        zIndex: 3,
      },
      [lg]: {
        left: '2rem',
      },
      [xl]: {
        left: '3.75rem',
      },
    },
    divider: {
      height: '12.8125rem',
      maxHeight: '30vh',
      width: '0.0938rem',
      backgroundColor: '#C0CCDA',
      [lg]: {
        height: '18.875rem',
        width: '0.125rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const SocialToolbar = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <SocialButton href={social.github} faIcon={faGithub} />
      <SocialButton href={social.linkedIn} faIcon={faLinkedin} />
      <div className={classes.divider} />
    </div>
  )
}

export default withStyles(styles)(SocialToolbar)
