import * as React from 'react'

function SvgDotsSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 55 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={0.607}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={1.453}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={7.374}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={13.294}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={19.215}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={25.136}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={31.056}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={36.976}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={42.897}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={48.817}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={0.607}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={5.953}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={11.298}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={16.643}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={21.988}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={27.334}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={32.679}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={38.024}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={43.37}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={48.715}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
      <ellipse
        cx={54.06}
        cy={54.738}
        rx={0.607}
        ry={0.611}
        fill="#101D49"
        fillOpacity={0.38}
      />
    </svg>
  )
}

export default SvgDotsSvg
