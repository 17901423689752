import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Svg from './DotsSvg'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: '3.0625rem',
      width: '3.4375rem',
      left: '-0.1875rem',
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        bottom: '4.625rem',
        width: '5.1875rem',
      },
      [theme.breakpoints.up('xl')]: {
        bottom: '-2.9375rem',
        width: '12.375rem',
        left: '-2.6875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Dots = ({ classes }: Props) => {
  return <Svg className={classes.root} />
}

export default withStyles(styles)(Dots)
