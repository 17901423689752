import { withStyles, createStyles, Theme } from '@material-ui/core'

import Dots from '../../components/Dots'
import { getFlexBreakpoint } from '../../components/SectionWithIcon/styles'

const styles = (theme: Theme) => {
  const {
    breakpoints: { up },
  } = theme
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      bottom: '7.6875rem',
      width: '7.3125rem',
      right: '2rem',
      display: 'none',
      [flexBreakpoint]: {
        display: 'block',
      },
      [up('xl')]: {
        bottom: '14.625rem',
        right: 0,
        width: '12.375rem',
      },
    },
  })
}

export default withStyles(styles)(Dots)
